import { noop } from "lodash"
import { reqMethod } from "types/Request"
import { getClientTimezone } from "utils/dates"
import { fetchAuthSession } from "aws-amplify/auth"
import { toast } from "react-toastify"
import store from "store"

const BASE_URL = process.env.BACKEND_URL ?? "https://api.sequel.care"

type requestOptions = [endpoint: string, body?: any, options?: Options]
type withAuth = "all" | "none" | "noRole"
type Options = RequestInit & { isNotJSON?: boolean }

export default class ApiRequest<R> {
    error: Error | null = null
    callerName: string | null = null

    private response: Response = null
    private responseBody: R = null
    private responseStatus: number = null
    private requestBody?: string
    private queryParams: string = ""
    private abortController?: AbortController

    constructor(private method: reqMethod, private endpoint: string, body?: any, private options: Options = {}) {
        this.handleParams(body, options?.isNotJSON)
    }

    get body() {
        return this.responseBody
    }

    get status() {
        return this.responseStatus
    }

    private bodyOrThrow() {
        if (this.error) {
            if (this.error.name === "AbortError" || this.error.message?.includes("operation was aborted")) {
                return
            }

            const message = this.error.message
            if (message) {
                toast.error(message)
                // return
            }
            // if (!this.error.message.includes("operation was aborted"))
            //     console.error(this.callerName ? `Failed in ${this.callerName}:` : `Fetch failed:`, this.error)
            throw this.error
        }

        return this.responseBody
    }

    enableAbort() {
        this.abortController = new AbortController()
    }

    get abort() {
        return this.abortController?.abort.bind(this.abortController)
    }

    async run(options?: { blobResponse: boolean }): Promise<R>
    async run(options?: { withoutContentType: boolean }): Promise<R>
    async run(options?: { withAuth: withAuth }): Promise<R>
    async run(options?: { blobResponse: boolean; withAuth: withAuth }): Promise<R>
    async run(options: { withAuth?: withAuth; returnInstance: true }): Promise<this>
    async run(options?: {
        withAuth?: withAuth
        returnInstance?: boolean
        withoutContentType?: boolean
        blobResponse?: boolean
    }) {
        const requestUrl = new URL(this.endpoint + this.queryParams, BASE_URL).href
        const headers = await this.getHeaders(options?.withAuth, options?.withoutContentType)

        try {
            this.response = await fetch(requestUrl, {
                method: this.method,
                credentials: "include",
                headers,
                body: this.requestBody,
                signal: this.abortController?.signal
            })

            this.responseStatus = this.response.status

            if (!this.response.ok) throw new Error(await this.response.text())

            let responseBody
            try {
                if (options?.blobResponse) {
                    this.responseBody = (await this.response.blob()) as any
                } else {
                    responseBody = await this.response.text()
                    this.responseBody = JSON.parse(responseBody)
                }
            } catch {
                // Response could not be parsed as JSON
                this.responseBody = responseBody as unknown as R
            }
        } catch (error: any) {
            try {
                this.error = JSON.parse(error?.message)
            } catch {
                this.error = error
            }
        }

        return options?.returnInstance ? this : this.bodyOrThrow()
    }

    handleParams(body: any, isNotJSON?: boolean) {
        if (isNotJSON || ["string", "undefined"].includes(typeof body)) return (this.requestBody = body)
        if (!["get", "delete"].includes(this.method)) return (this.requestBody = JSON.stringify(body))

        let queryParams = []
        for (const key in body) {
            if (typeof body[key] === "undefined") continue

            const value = ["string", "number", "boolean"].includes(typeof body[key])
                ? body[key]
                : "toJSON" in body[key]
                ? body[key].toJSON()
                : JSON.stringify(body[key])
            queryParams.push(`${key}=${value}`)
        }

        return (this.queryParams = `?${queryParams.join("&")}`)
    }

    async getHeaders(withAuth: withAuth = "all", withoutContentType?: boolean) {
        const contentType = withoutContentType
            ? {}
            : {
                  "Content-Type": "application/json"
              }
        const headers = new Headers({
            ...contentType,
            "X-Timezone": getClientTimezone(),
            ...this.options.headers
        })

        // Add Authorization headers, skip if withAuth is "none", skip organization header if withAuth is "noRole"
        if (withAuth !== "none")
            await Promise.all([
                new Promise<void>(async (resolve) => {
                    if (process.env.OFFLINE_USER_ID) {
                        headers.append("Authorization", "debug")
                        headers.append("X-USERID", process.env.OFFLINE_USER_ID)
                        return resolve()
                    }

                    const session = await fetchAuthSession()
                    if (!session) return

                    headers.append("Authorization", `Bearer ${session.tokens.idToken}`)
                    resolve()
                }),
                new Promise<void>((resolve) => {
                    if (withAuth === "noRole") resolve()

                    const addOrganizationHeader = () => {
                        const currentRole = store.getState().global.currentRole
                        if (!currentRole) return false

                        resolve()
                        return true
                    }

                    if (!addOrganizationHeader()) {
                        const unsubscribe = store.subscribe(() => addOrganizationHeader() && unsubscribe())
                    }
                })
            ])

        return headers
    }
}

export class ApiGet<R> extends ApiRequest<R> {
    constructor(...args: requestOptions) {
        super("get", ...args)
    }
}

export class ApiPatch<R> extends ApiRequest<R> {
    constructor(...args: requestOptions) {
        super("PATCH", ...args)
    }
}

export class ApiPost<R> extends ApiRequest<R> {
    constructor(...args: requestOptions) {
        super("post", ...args)
    }
}

export class ApiPut<R> extends ApiRequest<R> {
    constructor(...args: requestOptions) {
        super("put", ...args)
    }
}

export class ApiDelete<R> extends ApiRequest<R> {
    constructor(...args: requestOptions) {
        super("delete", ...args)
    }
}

export class SelfAbortingRequest<R> {
    private instance: ApiRequest<R> | null = null

    constructor(private method: reqMethod, private callerName?: string) {}

    get abort() {
        return this.instance?.abort || noop
    }

    async run(...args: requestOptions) {
        if (this.instance) this.instance.abort()

        this.instance = new ApiRequest<R>(this.method, ...args)
        this.instance.callerName = this.callerName
        this.instance.enableAbort()

        const data = await this.instance.run()
        this.instance = null
        return data
    }
}

export class SelfAbortingGet<R> extends SelfAbortingRequest<R> {
    constructor(callerName?: string) {
        super("get", callerName)
    }
}
